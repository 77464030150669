export const classRanks = [
  {
    id: 1,
    name: 'Brons',
    neededBits: 0,
    nameKey: 'RANKS.TYPES.BRONZE',
    shieldImageSrc: require('../../assets/img/img_bronze.png'),
    // cssColors: {
    //   baseColor: '#CFA585',
    //   backgroundGradient: 'linear-gradient(0deg, #815939 0%, #C59B7A 100%)',
    //   outerCircleColor: 'rgba(#956D4C, 0.2)',
    //   innerCircleColor: 'rgba(#9D7454, 0.2)',
    //   outlineColor: '#C59B7A',
    // },
  },
  {
    id: 2,
    name: 'Zilver',
    neededBits: 2000,
    nameKey: 'RANKS.TYPES.SILVER',
    shieldImageSrc: require('../../assets/img/img_silver.png'),
    // cssColors: {
    //   baseColor: '#BCBFC7',
    //   backgroundGradient: 'linear-gradient(0deg, #9A9EAB 0%, #DCDFE6 100%)',
    //   outerCircleColor: 'rgba(#9CA1AD, 0.2)',
    //   innerCircleColor: 'rgba(#9CA1AD, 0.2)',
    //   outlineColor: '#e5e5e5',
    // },
  },
  {
    id: 3,
    name: 'Goud',
    neededBits: 5000,
    nameKey: 'RANKS.TYPES.GOLD',
    shieldImageSrc: require('../../assets/img/img_gold.png'),
    // cssColors: {
    //   baseColor: '#BCBFC7',
    //   backgroundGradient: 'linear-gradient(0deg, #F7DD7D 0%, #EDB847 100%)',
    //   outerCircleColor: 'rgba(#EDBA49, 0.2)',
    //   innerCircleColor: 'rgba(#EDB949, 0.2)',
    //   outlineColor: '#F7DD7',
    // },
  },
  {
    id: 4,
    name: 'Diamant',
    neededBits: 8500,
    nameKey: 'RANKS.TYPES.DIAMOND',
    shieldImageSrc: require('../../assets/img/img_platinum.png'),
    // cssColors: {
    //   baseColor: '#92CADF',
    //   backgroundGradient: 'linear-gradient(356.34deg, #71C3D8 0%, #5194BF 100%)',
    //   outerCircleColor: 'rgba(#75C9E5, 0.2)',
    //   innerCircleColor: 'rgba(#75C9E5, 0.2)',
    //   outlineColor: '#c8e6f1',
    // },
  },
  {
    id: 5,
    name: 'Briljant',
    neededBits: 12500,
    nameKey: 'RANKS.TYPES.BRILLIANT',
    shieldImageSrc: require('../../assets/img/img_legendary.png'),
    // cssColors: {
    //   baseColor: '#EC2C93',
    //   backgroundGradient: 'linear-gradient(0deg, #3666AA 0%, #9D3278 100%)',
    //   outerCircleColor: 'rgba(#9A347A, 0.2)',
    //   innerCircleColor: 'rgba(#9A3379, 0.2)',
    //   outlineColor: '#c8e6f1',
    // },
  }
]
