<template>
  <div v-if="rank" class="rank-banner" :class="rankIdToClassName(rank.id)">
    <img src="../../../assets/img/elements_bg_pattern.png" class="icons">
    <Rank :rank="rank" style-preset="big" :header-text="$t('RANKINGS.ACHIEVED')" :bits-amount="bitsAmount" />
    <img src="../../../assets/img/elements_bg_pattern.png" class="icons right">
    <div class="spotlight" />
  </div>
</template>

<script>
import Rank from '@/components/elements/missions/Rank'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'

export default {
  name: 'RankBanner',
  components: {
    Rank
  },
  props: {
    rank: {
      type: Object,
      required: true
    },
    bitsAmount: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const store = useStore()

    function rankIdToClassName(id) {
      switch (id) {
        case 1:
          return 'bronze'
        case 2:
          return 'silver'
        case 3:
          return 'gold'
        case 4:
          return 'diamond'
        case 5:
          return 'brilliant'
        default:
          return 'bronze'
      }
    }

    return {
      store,
      rankIdToClassName
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.rank-banner {
  background: linear-gradient(0deg, #2A6BAF 0%, #A92D73 100%);
  border-radius: rem(20);
  margin-bottom: rem(23);
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: rem(20) rem(43);
  gap: rem(34);

  &.bronze {
    background: linear-gradient(0deg, #815939 0%, #C59B7A 100%);
  }

  &.silver {
    background: linear-gradient(0deg, #9A9EAB 0%, #DCDFE6 100%);
  }

  &.gold {
    background: linear-gradient(0deg, #F7DD7D 0%, #EDB847 100%);
  }

  &.diamond {
    background: linear-gradient(356.34deg, #71C3D8 0%, #5194BF 100%);
  }

  &.briljant {
    background: linear-gradient(0deg, #3666AA 0%, #9D3278 100%);
  }

  .spotlight {
    position: absolute;
    background: linear-gradient(0deg, rgba(white, 0) 0%, rgba(white, 0.3) 100%);
    width: rem(150);
    height: rem(70);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
  }

  .icons {
    object-fit: contain;
    object-position: center;
    flex-shrink: 1;
    width: 100%;
    height: rem(150);

    &.right {
      transform: scaleX(-1);
    }
  }

  .rank {
    flex-shrink: 0;
  }
}
</style>
