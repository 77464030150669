<template>
  <div v-if="displayed" class="dropdown-container">
    <div class="choice-selector" @mousedown="toggleOpen()">
      <div class="choice-content-wrapper">
        <img v-if="icon" :src="icon" alt="" class="dropdown-icon" />
        <p class="choiceSelector">{{ name }}</p>
      </div>
      <InlineSvg :src="require('../../../assets/icons/icn_arrow.svg')" class="arrow" :class="open ? 'open' : 'closed'" />
    </div>
    <div v-show="open" class="dropdown-content scrollable">
      <div
        v-for="option in options"
        :key="option"
        class="dropdown-item"
        :class="{'selected': option.id === selectedElement.id}"
        @mousedown="selected(option)"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'DropdownSingle',
  components: {
    InlineSvg
  },
  props: {
    // 'Top display choice'
    name: {
      type: String,
      required: true,
    },
    /**
     * Options of dropdown
     * @type Array<Object>
     * each object should have an id at least
     */
    options: {
      type: Array,
      required: true,
    },
    // 'Whether the dropdown is open'
    startOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    onselect: {
      type: Function,
      required: false,
      default: () => {}
    },
    displayed: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false,
      default: 'aa'
    },
    /**
     * Should have an id at least
     */
    selectedElement: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      open: false,
    }
  },
  created() {
    this.open = this.startOpen
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
    selected(choice) {
      this.onselect(choice)
      if (this.open) {
        this.toggleOpen()
      }
    }
  },
  template: 'DropdownSingle'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$max-height-dropdown-content: rem(420);

.dropdown-container {
  position: relative;
  display: block;
  cursor: pointer;
  width: fit-content;
}

.choice-selector{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: rem(12);

  .choice-content-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: rem(12);
  }

  p {
    color: #0E4E95;
    font-weight: 500;
  }
}

.arrow {
  width: rem(8);
  transition: transform 0.35s;
  :deep(g path) {
    fill: #0E4E95;
  }
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: rem(30);
  left:rem(-10);
  box-shadow: 3px 2px 0 0 rgba(0,0,0,0.1);
  z-index: 4;
  width: rem(300);
  margin: rem(1) rem(1);
  border-radius: rem(8);
  max-height: $max-height-dropdown-content;
  overflow-y: auto;
}

.dropdown-item {
  font-size: rem(16);
  padding: rem(17) rem(16);
  border-bottom: 2px solid rgba(128, 128, 128, 0.082);
  color: var(--blue_dark);
  background-color: white;

  &:first-of-type {
    border-radius: rem(8) rem(8) 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 rem(8) rem(8);
  }
  &:only-of-type {
    border-radius: rem(8) rem(8) rem(8) rem(8);
  }

  &:hover {
    background-color: #e7ecf5;
  }
  &.selected {
    font-weight: 600;
  }
}

.open {
  transform: rotate(-90deg);
}

.closed {
  transform: rotate(90deg);
}

</style>
