<template>
  <div v-if="rank" class="rank" :class="[rankIdToClassName(rank.id), stylePreset]">
    <template v-if="stylePreset === 'big'">
      <div class="rank-inner">
        <h2 class="header">{{ headerText }}</h2>
        <div class="outer-circle">
          <div class="inner-circle">
            <img :src="rank.shieldImageSrc" alt="" class="shield-icon">
            <div v-if="bitsAmount !== null" class="current-amount-bits" :class="{ 'prize-banner': usePrizeBanner }">
              <InlineSvg class="bits-icon" :src="require('@/assets/icons/icn_bits.svg')" />
              <p class="current-amount-bits-text">{{ bitsAmount }}</p>
            </div>
          </div>
        </div>
        <p class="rank-type-text">{{ $t(rank.nameKey) }}</p>
      </div>
    </template>
    <template v-else-if="stylePreset === 'small'">
      <div class="rank-inner-small">
        <div class="rank-inner-small-left">
          <div class="outer-circle">
            <div class="inner-circle">
              <img :src="rank.shieldImageSrc" alt="" class="shield-icon">
              <div v-if="bitsAmount !== null" class="current-amount-bits" :class="{ 'prize-banner': usePrizeBanner }">
                <p class="current-amount-bits-text">{{ bitsAmount }}</p>
                <InlineSvg class="bits-icon" :src="require('@/assets/icons/icn_bits.svg')" />
              </div>
            </div>
          </div>
        </div>
        <div class="rank-inner-small-right">
          <h2 class="header">{{ headerText }}</h2>
          <p class="rank-type-text">{{ $t(rank.nameKey) }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'Rank',
  components: {
    InlineSvg
  },
  props: {
    rank: {
      type: Object,
      required: true,
    },
    headerText: {
      type: String,
      required: false,
      default: ''
    },
    bitsAmount: {
      type: Number,
      required: false,
      default: null
    },
    usePrizeBanner: {
      type: Boolean,
      required: false,
      default: false
    },
    // The different styles this rank can appear in.
    // - big: Used for the currently selected rank.
    // - small: Used for the previous and next ranks.
    stylePreset: {
      type: String,
      required: false,
      default: 'small'
    }
  },
  setup(props) {
    // const store = useStore()

    function rankIdToClassName(id) {
      switch (id) {
        case 1:
          return 'bronze'
        case 2:
          return 'silver'
        case 3:
          return 'gold'
        case 4:
          return 'diamond'
        case 5:
          return 'brilliant'
        default:
          return 'bronze'
      }
    }

    return {
      // store,
      rankIdToClassName
    }
  }
}

</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

// used for active rank
$outer-rank-circle-width-big: 150px;
$shield-big-width: 100px;

// used for next / previous ranks
$outer-rank-circle-width-small: 75px;
$shield-small-width: 50px;

p, h2 {
  color: white;
}
.rank {
  border-radius: rem(16);
  position: relative;

  .rank-inner {
    position: absolute;
    top: rem(20);
    right: rem(18);
    bottom: rem(20);
    left: rem(18);
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      font-size: rem(16);
      font-weight: 500;
      margin-top: rem(4);
      margin-bottom: rem(4);
    }

    .outer-circle {
      border-radius: 50%;
      position: relative;

      .inner-circle {
        position: absolute;
        background-color: inherit;
        border-radius: inherit;

        .shield-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-50% + 3px));
        }

        .current-amount-bits {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: rem(2);

          .current-amount-bits-text {
            font-size: rem(22);
            font-weight: 500;
          }

          &.prize-banner {
            flex-direction: row-reverse;
            background: linear-gradient(180deg, #f8f8f8 0%, #f7f7f7 100%);
            width: rem(124);
            justify-content: center;
            gap: rem(4);

            .current-amount-bits-text {
              color: #373B52;
              font-size: rem(14);
              padding: rem(5) 0;
            }
            .bits-icon {
              width: rem(14);
              height: rem(14);
              :deep g path {
                fill: #373B52;
              }
            }
          }
        }
      }
    }

    .rank-type-text {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      font-weight: 500;
      text-transform: uppercase;
      padding: rem(6) rem(10);
      width: fit-content;
      border-radius: rem(6);
    }
  }

  // Big variant
  &.big {
    width: rem(400);
    height: rem(240);
    box-shadow: 0 0 20px 3px rgba(0,0,0,0.1);

    .rank-inner {
      border-radius: rem(12);
      border: rem(2) solid rgba(white, 0.31);

      .outer-circle {
        width: $outer-rank-circle-width-big;
        height: $outer-rank-circle-width-big;
        background-color: rgba(white, 0.2);
        position: relative;

        .inner-circle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: calc(#{$outer-rank-circle-width-big} * 0.8);
          width: calc(#{$outer-rank-circle-width-big} * 0.8);
          background-color: rgba(white, 0.2);
        }
      }
    }
  }

  // Small variant
  &.small {
    width: rem(220);
    height: rem(110);
    background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);

    outline: solid 3px #E5E5E5;

    .rank-inner-small {
      display: flex;
      align-items: center;
      padding: 16px;
      justify-content: space-between;

      .rank-inner-small-left {
        .outer-circle {
          width: $outer-rank-circle-width-small;
          height: $outer-rank-circle-width-small;
          background-color: rgba(#9CA1AD, 0.2);
          position: relative;
          border-radius: 50%;

          .inner-circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: calc(#{$outer-rank-circle-width-small} * 0.8);
            width: calc(#{$outer-rank-circle-width-small} * 0.8);
            background-color: rgba(#9CA1AD, 0.2);
            border-radius: 50%;

            .shield-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, calc(-50% + 3px));
              height: 75%;
              width: 75%;
            }

            .current-amount-bits {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 20px;
              background-color: white;
              display: flex;
              justify-content: center;
              width: $outer-rank-circle-width-small;
              align-items: center;

              .current-amount-bits-text {
                color: #373B52;
                margin-right: 4px;
                font-size: rem(12);
                font-weight: 500;
              }

              .bits-icon {
                width: rem(18);
                height: rem(18);
              }
            }
          }
        }
      }
      .rank-inner-small-right {
        height: $outer-rank-circle-width-small;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 16px;
        padding-top: 6px;
        padding-bottom: 6px;

        .header {
          font-weight: 500;
          font-size: 13px;
          color: #373B52;
        }

        .rank-type-text {
          font-weight: 700;
          text-transform: uppercase;
          padding: rem(6) rem(10);
          width: fit-content;
          border-radius: rem(6);
          font-size: 15px;
        }
      }
    }
  }

  // Rank color styling
  &.bronze {
    &.small {
      outline: solid 3px #CFA585;
    }


    .rank-type-text {
      background-color: #CFA585;
    }
    &.big {
      background: linear-gradient(0deg, #815939 0%, #C59B7A 100%);
    }
    &.small {
      .rank-inner {
        .outer-circle {
          background-color: rgba(#956D4C, 0.2);

          .inner-circle {
            background-color: rgba(#9D7454, 0.2);
          }
        }
      }
    }
  }
  &.silver {
    &.small {
      outline: solid 3px #e5e5e5;
    }

    .rank-type-text {
      background-color: #BCBFC7;
    }
    &.big {
      background: linear-gradient(0deg, #9A9EAB 0%, #DCDFE6 100%);
    }
    &.small {
      .rank-inner {
        .outer-circle {
          background-color: rgba(#9CA1AD, 0.2);

          .inner-circle {
            background-color: rgba(#9CA1AD, 0.2);
          }
        }
      }
    }
  }
  &.gold {
    &.small {
      outline: solid 3px #edb646;
    }

    .rank-type-text {
      background-color: #EDB646;
    }
    &.big {
      background: linear-gradient(0deg, #F7DD7D 0%, #EDB847 100%);
    }
    &.small {
      .rank-inner {
        .outer-circle {
          background-color: rgba(#EDBA49, 0.2);

          .inner-circle {
            background-color: rgba(#EDB949, 0.2);
          }
        }
      }
    }
  }
  &.diamond {
    &.small {
      outline: solid 3px #92cadf;
    }

    .rank-type-text {
      background-color: #92CADF;
    }
    &.big {
        background: linear-gradient(356.34deg, #71C3D8 0%, #5194BF 100%);
    }
    &.small {
      .rank-inner {
        .outer-circle {
          background-color: rgba(#75C9E5, 0.2);

          .inner-circle {
            background-color: rgba(#75C9E5, 0.2);
          }
        }
      }
    }
  }
  &.brilliant {
    &.small {
      outline: solid 3px #EC2C93;
    }

    .rank-type-text {
      background-color: #EC2C93;
    }
      &.big {
        background: linear-gradient(0deg, #3666AA 0%, #9D3278 100%);
    }
    &.small {
      .rank-inner {
        .outer-circle {
          background-color: rgba(#9A347A, 0.2);

          .inner-circle {
            background-color: rgba(#9A3379, 0.2);
          }
        }
      }
    }
  }
}

</style>
