<template>
  <div class="current-week">
    <div class="rank-wrapper">
      <div class="rank-container">
        <div class="background-stripe" />
        <div class="ranks">
          <Rank
            v-if="prevRank"
            :rank="prevRank"
            style-preset="small"
            header-text="Vorige rang"
          /> <!-- TODO: Use translation. -->
          <Rank
            :rank="currentRank"
            style-preset="big"
            header-text="Huidige rang"
            :bits-amount="currentAmountOfBits"
          /> <!-- TODO: Use translation. -->
          <Rank
            v-if="nextRank"
            :rank="nextRank"
            style-preset="small"
            header-text="Volgende rang"
            :bits-amount="nextRank.neededBits"
            :use-prize-banner="true"
          /> <!-- TODO: Use translation. -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rank from '@/components/elements/missions/Rank'
import { computed } from '@vue/runtime-core'
import { classRanks } from '@/utils/data/classRanks'

export default {
  name: 'CurrentWeek',
  components: {
    Rank,
  },
  props: {
    currentAmountOfBits: {
      type: Number,
      required: true
    },
    currentRank: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const ranks = classRanks
    // console.log('ranks', ranks)

    const prevRank = computed(() => {
      return ranks.find(rank => rank.id === props.currentRank.id - 1)
    })
    const nextRank = computed(() => {
      return ranks.find(rank => rank.id === props.currentRank.id + 1)
    })

    // console.log('ranks', ranks)

    return {
      prevRank,
      nextRank,
    }
  },
  template: 'CurrentWeek',

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.rank-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(26);
  width: 100%;

  .rank-container {
    position: relative;
    .background-stripe {
      background-color: #e5e5e5;
      position: absolute;
      width: 100%;
      height: rem(4);
      top: 50%;
      transform: translateY(-50%);
    }

    .ranks {
      display: flex;
      gap: rem(40);
      justify-content: center;
      align-items: center;
    }
  }
}

.missions-wrapper {
  display: flex;
  flex-wrap: wrap;
}

</style>
